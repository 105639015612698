import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainBlack,
  graphik,
  larsseit,
  screen,
} from "../components/common/variables";
import ContactForm from "../components/contact-form";
import AnimatedHeading from "../components/animated-heading/about-page";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  background: ${mainWhite};

  .about-intro {
    &__text {
      padding: 0 26px;
      @media ${screen.xsmall} {
        padding: 0 60px;
      }
      @media ${screen.small} {
        padding: 0 80px;
      }
      @media ${screen.medium} {
        padding: 0 7vw;
      }
    }
    &__heading {
      margin-bottom: 40px;
      @media ${screen.xlarge} {
        margin-bottom: 60px;
      }

      h1 {
        color: ${mainBlack};
        font-family: ${larsseit};
        line-height: 1.23;
        font-size: 2.53rem;
        @media ${screen.xsmall} {
          font-size: 3.3rem;
        }
        @media ${screen.small} {
          font-size: 4rem;
        }
        @media ${screen.medium} {
          font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
        }

        strong {
          color: ${mainBlue};
        }

        .break {
          display: block;
        }
      }
    }

    &__description {
      color: ${mainBlack};
      font-family: ${graphik};
      max-width: 665px;
      @media ${screen.medium} {
        max-width: 770px;
      }

      p {
        font-size: 1.1rem;
        margin: 0 0 20px 0;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
          margin: 0 0 30px 0;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &__featured-img {
      display: flex;
      padding: 0 26px 0 0;
      height: calc(200px + 5vw);
      margin: 70px 0 40px 0;
      @media ${screen.xsmall} {
        height: calc(200px + 17vw);
        width: 75%;
        padding: 0 7vw 0 0;
        margin: 40px 0 80px 0;
      }
      @media ${screen.small} {
        height: calc(200px + 17vw);
        margin: 90px 0;
        width: auto;
      }
      @media ${screen.medium} {
      }
      @media ${screen.xlarge} {
        margin: 120px 0;
      }

      .img-left {
        width: 100%;
        background-image: ${(props) => `url(${props.featured_image_left})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 16px;
        @media ${screen.medium} {
          width: 63%;
        }
      }

      .img-right {
        width: 100%;
        display: none;
        @media ${screen.small} {
          display: block;
          background-image: ${(props) => `url(${props.featured_image_right})`};
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        @media ${screen.medium} {
          width: 37%;
        }
      }
    }
  }

  .about-secondary {
    padding: 0 26px;
    @media ${screen.xsmall} {
      display: flex;
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      @media ${screen.xsmall} {
        width: 35%;
        margin-right: 40px;
      }
      @media ${screen.small} {
        margin-right: 60px;
      }

      h1,
      h2 {
        color: ${mainBlack};
        font-family: ${larsseit};
        font-size: 2.3rem;
        line-height: 1.17;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          margin: 0;
          max-width: 350px;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
      }
    }

    &__description {
      color: ${mainBlack};
      font-family: ${graphik};
      @media ${screen.xsmall} {
        width: 65%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${mainBlack};
        font-family: ${larsseit};
        font-size: 1.5rem;
        margin-bottom: 20px;
        font-weight: 500;
        @media ${screen.xsmall} {
          font-size: 1.2rem;
          margin-bottom: 6px;
          max-width: 700px;
        }
        @media ${screen.small} {
          font-size: 1.4rem;
        }
        @media ${screen.medium} {
          font-size: clamp(1.4rem, calc(0.5rem + 1vw), 1.7rem);
          margin-bottom: 22px;
        }

        strong {
          color: ${mainBlue};
          font-weight: 500;
        }
      }

      p {
        font-size: 1.1rem;
        margin-bottom: 50px;
        max-width: 800px;
        @media ${screen.small} {
          font-size: 1.15rem;
          margin-bottom: 50px;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
          margin-bottom: 50px;
        }
      }

      &:last-child p {
      }
    }
  }

  .about-team {
    margin: 70px 0;
    @media ${screen.small} {
      margin: 90px 0 100px 0;
    }
    @media ${screen.xlarge} {
      margin: 120px 0;
    }
    &__heading {
      padding: 0 26px;
      h1,
      h2 {
        color: ${mainBlack};
        font-family: ${larsseit};
        line-height: 1.17;
        font-size: 2.3rem;
        @media ${screen.xsmall} {
          text-align: center;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
      }
    }

    &__list {
      margin: -10px 0 0 0;
      @media ${screen.xsmall} {
        display: flex;
        flex-wrap: wrap;
        padding-left: 60px;
        padding-right: 60px;
        margin: -10px -10px 0 -10px;
      }
      @media ${screen.small} {
        padding-left: 80px;
        padding-right: 80px;
        margin: 0 -10px;
      }
      @media ${screen.medium} {
        padding-left: 100px;
        padding-right: 100px;
        margin: 0 -10px;
      }
      @media ${screen.large} {
        padding-left: 140px;
        padding-right: 140px;
        margin: 0 -10px;
      }

      .member {
        color: ${mainBlack};
        font-family: ${larsseit};

        margin-top: 40px;
        @media ${screen.xsmall} {
          margin-top: 60px;
          margin-left: 10px;
          margin-right: 10px;
          width: calc(50% - 20px);
        }
        @media ${screen.small} {
          width: calc(33.33% - 20px);
        }

        &__img {
          position: relative;

          .hover-gif {
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
          }

          @media ${screen.withCursor} {
            &:hover {
              .hover-gif {
                visibility: visible;
              }
            }
          }
        }

        &__person-name {
          display: block;
          padding: 0 26px;
          margin: 18px 0 9px 0;
          font-size: 0.9rem;
          font-weight: 700;
          @media ${screen.xsmall} {
            padding: 0;
          }
          @media ${screen.small} {
            margin: 18px 0 15px 0;
            font-size: 1.1rem;
          }
          @media ${screen.medium} {
            font-size: 1.5rem;
            margin: 30px 0 15px 0;
          }
        }

        &__position {
          display: block;
          padding: 0 26px;
          font-size: 0.9rem;
          font-weight: 500;
          @media ${screen.xsmall} {
            padding: 0;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          @media ${screen.medium} {
            font-size: 1.12rem;
          }
        }

        &__description {
          font-size: 0.9rem;
          font-weight: 400;
          padding: 0 26px;
          max-width: 380px;
          margin: 5px 0 0 0;
          @media ${screen.xsmall} {
            padding: 0;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
            margin: 6px 0 0 0;
          }
          @media ${screen.medium} {
            font-size: 1.12rem;
          }
          @media ${screen.large} {
            max-width: 430px;
          }
        }
      }
    }
  }
`;
const AboutPage = ({ data }) => {
  // all content from prismic via graphql query at the bottom
  const {
    title_tag,
    meta_description,
    intro_description,
    featured_image_left,
    featured_image_right,
    secondary_heading,
    secondary_description,
    team_heading,
    members,
  } = data.content.data;

  // return a string url to use for meta details
  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title={title_tag}
        description={meta_description}
        image={featured_image_left.url}
        webPageInfo={{
          name: title_tag,
          url,
          description: meta_description,
        }}
      />
      <Wrapper
        featured_image_right={featured_image_right.url}
        featured_image_left={featured_image_left.url}
      >
        <div className="about-intro">
          <div className="about-intro__text">
            <div className="about-intro__heading">
              <AnimatedHeading />
            </div>

            <div
              className="about-intro__description"
              dangerouslySetInnerHTML={{ __html: intro_description.html }}
            />
          </div>
          <div
            className="about-intro__featured-img"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <div className="img-left"></div>
            <div className="img-right"></div>
          </div>
        </div>
        <div className="about-secondary">
          <div
            className="about-secondary__heading"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h2>{secondary_heading.text}</h2>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            className="about-secondary__description"
            dangerouslySetInnerHTML={{ __html: secondary_description.html }}
          />
        </div>

        <div className="about-team">
          <div
            className="about-team__heading"
            data-sal="slide-up"
            data-sal-duration="500"
            dangerouslySetInnerHTML={{ __html: team_heading.html }}
          />
          <ul className="about-team__list">
            {members.map((item) => (
              <li
                className="member"
                key={item.name}
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <div className="member__img">
                  {item.image.gatsbyImageData && (
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt={item.image.alt || "Handsome Creative - Member"}
                    />
                  )}
                  <img
                    className="hover-gif"
                    src={item.hover_gif.url}
                    alt={item.hover_gif.alt || "Handsome Creative - Member"}
                  />
                </div>
                <span className="member__person-name">{item.name}</span>
                <span className="member__position">{item.position}</span>
                <p className="member__description">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
        <ContactForm
          headingText={
            "Get in touch to find out how we can help with your next project."
          }
        />
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        title_tag
        meta_description
        intro_description {
          html
        }
        featured_image_left {
          alt
          url
        }
        featured_image_right {
          alt
          url
        }
        secondary_heading {
          text
        }
        secondary_description {
          html
        }
        team_heading {
          html
        }
        members {
          name
          position
          description
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          hover_gif {
            alt
            url
          }
        }
      }
    }
  }
`;
