import React from "react";
import styled, { css } from "styled-components";
import { fadeIn } from "./fadeIn";

const Wrapper = styled.h1`
  .char {
    opacity: 0;
  }
`;

const AnimatedHeading = ({ className }) => {
  return (
    <Wrapper className={className}>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        W
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.13s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        e
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.16s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.19s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        d
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.22s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        e
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        s
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.28s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        i
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.31s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        g
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.34s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.37s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        a
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.43s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.46s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        d
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.49s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.52s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        b
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        u
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.58s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        i
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.61s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        l
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.64s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        d
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.67s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        b
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.73s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        r
      </span>

      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.76s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        a
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.79s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.82s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        d
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.85s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        s
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.88s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        .
      </span>
    </Wrapper>
  );
};

export default AnimatedHeading;
